// import request from '../../../../utils/request';
import BasePage from '../../../../components/page_base';
import accessConfig from './associated_position/associated_position.vue';

export default {
  name: 'conditionStub',
  extends: BasePage,
  components: {
    accessConfig,
  },
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
      cellClickConfig: {
        functionCode: 'mdm_process_role_form',
        queryUrl: '/mdm/mdmBpmRoleController/detail',
      },
    };
  },
  methods: {

    // 按钮点击前置
    beforeClickEvent({ val, row }) {
      if (val.code === 'export') {
        this.exportClick({ val });
      } else if (val.code === 'import') {
        this.importClick({ val });
      } else {
        return true;
      }
    },

    beforeModalClick({ val, row }, rule) {
      // 重置
      this.formName = 'Form';
      if (val.code === 'add' || val.code === 'edit' || val.code === 'view') {
        this.modalConfig.width = '600px';
        this.modalConfig.height = '400px';
        this.modalConfig.type = 'Modal';
        this.formConfig.code = val.code;
      }
      if (val.code === 'related_position') {
        this.modalConfig.title = '关联职位';
        this.formName = 'accessConfig';
        this.modalConfig.type = 'Drawer';
        this.formConfig = row;
        this.openFull();
      }
      return { val, row, rule };
    },
    setFormField(field) {
      const fields = field;
      console.log(fields);
      if (fields.field === 'enableStatus') {
        fields.value = '009';
      }
      if (this.modalConfig.config.code === 'edit') {
        this.modalConfig.title = '编辑';
        if (fields.field === 'bpmRoleCode') {
          fields.props.disabled = true;
        }
      } else if (this.modalConfig.config.code === 'view') {
        this.modalConfig.title = '查看';
      } else if (this.modalConfig.config.code === 'add') {
        this.modalConfig.title = '新增';
      }
      if (fields.field === 'bpmRoleCode') {
        fields.validate = [
          ...fields.validate,
          {
            trigger: 'blur',
            pattern: '^[a-zA-Z0-9]*$',
            message: '仅能输入英文和数字的组合',
          },
        ];
      }
      return fields;
    },
    setColumn(col) {
      console.log(col);
      const rowData = col;
      if (col.field === 'updateDateAll') {
        rowData.formatter = ({ row }) => {
          if (row.updateDateAll === 'null null') {
            return '';
          }
          return row.updateDateAll;
        };
      }
      return col;
    },
  },
};
